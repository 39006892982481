import { Fragment } from 'react'
import { UIRouter, UIView, pushStateLocationPlugin } from '@uirouter/react';
import states from '../states';
import config from '../states/config';
const propsUIRouter = {
    config,
    states,
    plugins: [pushStateLocationPlugin],
    children: <UIView  />
  }

const App = () => {
    return(
        <Fragment>
            <UIRouter {...propsUIRouter} />
        </Fragment>
    );
}

export default App;