import { useState, useEffect } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
//
import Context from "./Context";
//
import axios from "axios";
import authService from "./index";
import { useMsal } from "@azure/msal-react";
import { loginRequestAPI } from "./authConfig";
import { get } from "lodash";
import MixPanel from "../util/MixPanel";

const BASE_PATH = process.env.REACT_APP_API_BASEURL;

export default function AuthProvider(attr) {
  const [value, setValue] = useState(authService.state);
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const isDrAuthenticated = get(value, 'user.isDrAuthenticated', false)

  useEffect(() => {
    authService.evalDrLogin()
    authService.subscribe(setValue);
  }, []);

  useEffect(() => {

    if (isAuthenticated) {
      authService.setGetting(true);
      instance
        .acquireTokenSilent({
          ...loginRequestAPI,
          account: accounts[0],
        })
        .then((response) => {
          const axiosConfig = {
            url: `${BASE_PATH}/catalogs/operations`,
            method: 'GET',
            headers: { Authorization: `Bearer ${response.accessToken}` },
          }
          axios(axiosConfig).then(resp => authService.setClient(resp.data))

          authService.setLogin(
            response.accessToken,
            response.account.name,
            response.account.username,
            response.account.idTokenClaims.roles
          )

          MixPanel.identify(response.account.username);
          MixPanel.people.set({
            $name: response.account.name,
            $email: response.account.username,
          });
          MixPanel.track("Login", { login_type: 'msal' });
        });
    }
    if (isDrAuthenticated) {
      const axiosConfig = {
        url: `${BASE_PATH}/catalogs/operations`,
        method: 'GET',
        headers: { Authorization: `DR ${value.user.token}` },
      }
      axios(axiosConfig).then(resp => authService.setClient(resp.data))
      const rawUserData = value.user.token.split('.')[1]
      let user = {}
      try {
        user = JSON.parse(atob(rawUserData))
      } catch (e) {

      }
      authService.setLogin(value.user.token, user.name, user.username, user.roles, true)
      MixPanel.identify(user.username);
      MixPanel.people.set({
        $name: user.name,
        $email: user.username,
      });
      MixPanel.track("Login", { login_type: 'dr' });
    }
  }, [isAuthenticated, accounts, instance, isDrAuthenticated]);
  // Add accounts and instance as dependencies is generating an error on Logout
  // proccess, do you realy need those dependencies?

  const props = {
    value,
    ...attr,
  };

  return <Context.Provider {...props} />;
}
