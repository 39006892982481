// helpers
import { cloneDeep } from "lodash";
import set from "lodash/set";
import get from 'lodash/get'
import MixPanel from "../util/MixPanel";

const handler = {
  set: function (obj, prop, value) {
    authService.observers.forEach((ob) => ob({ ...obj, [prop]: value }));
    set(obj, prop, value);
    return true;
  },
};

const authService = {
  state: new Proxy(
    {
      getting: false,
      user: {
        name: null,
        token: null,
        username: null,
        roles: null,
        isDrAuthenticated: false
      },
      client: {
        operations: [],
        apps: [],
        operationSelected: 0,
        isLoadingApps: false
      }
    },
    handler
  ),

  observers: [],

  subscribe: function (observer) {
    this.observers.push(observer);
  },
  setGetting(val) {
    this.state.getting = val;
  },
  setToken(val) {
    const user = cloneDeep(this.state.user)

    user.token = val;

    this.state.user = user
  },
  logout() {
    const user = cloneDeep(this.state.user)
    window.localStorage.removeItem('executiveDashboard_DRToken')
    MixPanel.unregister({ email: user.username});

    user.token = null;
    user.name = null;
    user.username = null;
    user.roles = null;

    if (user.isDrAuthenticated) {
      user.isDrAuthenticated = false
      MixPanel.track("Logout", { login_type: 'dr' });

      this.state.user = user

      window.location.reload()
    } else {
      MixPanel.track("Logout", { login_type: 'msal' });
      this.state.user = user
    }
    MixPanel.reset();
  },
  evalDrLogin() {
    const user = cloneDeep(this.state.user)
    const drToken = window.localStorage.getItem('executiveDashboard_DRToken')

    if (drToken === null) return

    const drTokenRaw = drToken.split('.')

    if (drTokenRaw.length !== 3) return
    let rawUser = {}

    try {
      rawUser = JSON.parse(atob(drTokenRaw[1]))
    } catch (e) {
      return
    }

    user.token = drToken;
    user.name = get(rawUser, 'name', '');
    user.username = get(rawUser, 'username', '');
    user.roles = get(rawUser, 'roles', [])
    user.isDrAuthenticated = true

    this.state.getting = false;
    this.state.user = user;

  },
  setDRToken(val) {
    const user = cloneDeep(this.state.user)
    window.localStorage.setItem('executiveDashboard_DRToken', val)

    user.token = val;
    user.isDrAuthenticated = true

    this.state.getting = true;
    this.state.user = user;
  },
  setLogin(val, name, username, roles, isDrAuthenticated = false) {
    const user = cloneDeep(this.state.user)
    user.token = val;
    user.name = name;
    user.username = username;
    user.roles = roles
    user.isDrAuthenticated = isDrAuthenticated

    if (isDrAuthenticated) {
      window.localStorage.setItem('executiveDashboard_DRToken', val)
    }

    this.state.getting = false;
    this.state.user = user;
  },
  setClient(operations) {
    const client = cloneDeep(this.state.client)
    client.operations = operations;
    if (operations.length > 0)
      client.operationSelected = client.operations[0].id;

    this.state.client = client;
  },
  setOperation(operationSelected, apps) {
    const client = cloneDeep(this.state.client)

    client.operationSelected = operationSelected;
    client.apps = apps;

    this.state.client = client;
  },
  setLoadingApps(value) {
    const client = cloneDeep(this.state.client)
    client.isLoadingApps = value;

    this.state.client = client;
  }
};

export default authService;
