import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./containers";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authService/authConfig";
import AuthProvider from "./authService/AuthProvider";
import authService from "./authService";
import { EventType } from "@azure/msal-browser";
import QueryProvider from "./QueryProvider";

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.addEventCallback((message) => {
  if ([EventType.LOGIN_SUCCESS,EventType.ACQUIRE_TOKEN_FAILURE,EventType.ACQUIRE_TOKEN_SUCCESS, EventType.LOGIN_FAILURE].includes(message.eventType)) {
    authService.setGetting(false);
    if ([EventType.LOGIN_SUCCESS,EventType.ACQUIRE_TOKEN_SUCCESS].includes(message.eventType)) {
      window.localStorage.removeItem('executiveDashboard_DRToken')
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <QueryProvider>
          <App />
        </QueryProvider>
      </AuthProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
