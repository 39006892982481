// envents
import events from '../authService/constants'
import event from '../authService/events'
// helpers
import get from 'lodash/get'
//import size from 'lodash/size'

//const app = "Executive Dashboard"

export default function config(router) {
  router.transitionService.onBefore({}, transition => {
    const options = get(transition?.options(), 'custom', {})
    const from = transition.exiting()
    //const to = transition?.entering()
    const actualView = from[0]
    //const newView = to[size(to) - 1]
    //const { countryGroup = null } = store.get(app, {})

    if (options?.ignoreOnBefore) return Promise.resolve()

    //if (!newView?.data?.countryGroups && !isNull(countryGroup))
    //  return new Promise((resolve, reject) => {
    //    event.emit(events?.SINGLE_COUNTRY_SELECTION, { resolve, reject })
    //  })

    //if (singletonWS.state.taskId)
    //  return new Promise((resolve, reject) => {
    //    event.emit(events?.WS_VERIFICATION_RUNNING, { resolve, reject })
    //  })

    if (actualView?.data?.generalOnBefore)
      return new Promise((resolve, reject) => {
        event.emit(events?.GENERAL_ON_BEFORE, {
          resolve,
          reject,
          msg: actualView?.data?.generalOnBefore
        })
      })
  })

  return router.urlRouter.otherwise('/')
}