import mixpanel from "mixpanel-browser"

const isProduction = process.env.NODE_ENV === 'production';
const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN
const isMixpanelTokenAvailable = !!mixpanelToken;
const isExecutiveDashboard = ["executive-dashboard.imox.io"].includes(window.location.hostname);
const canTrackEvent = isMixpanelTokenAvailable && isExecutiveDashboard;

if (isProduction) {
    if (isMixpanelTokenAvailable)
        mixpanel.init(mixpanelToken);
} else {
if (isMixpanelTokenAvailable)
    mixpanel.init(mixpanelToken, { debug: true });
}

const MixPanel = {
    register: (props, days = null) => {
      if (canTrackEvent) {
        if (days) {
          mixpanel.register(props, days)
        } else {
          mixpanel.register(props)
        }
      }
    },
    register_once: (props) => {
      if (canTrackEvent) mixpanel.register_once(props);
    },
    unregister: (props) => {
      if (canTrackEvent) mixpanel.unregister(props);
    },
    identify: (id) => {
      if (canTrackEvent) mixpanel.identify(id);
    },
    alias: (id) => {
      if (canTrackEvent) mixpanel.alias(id);
    },
    track: (eventName, props) => {
      if (canTrackEvent) mixpanel.track(eventName, props);
    },
    track_with_groups: (eventName, props, groups) => {
      if (canTrackEvent) mixpanel.track_with_groups(eventName, props, groups);
    },
    set_group: (key, ids) => {
      if (canTrackEvent) mixpanel.set_group(key, ids);
    },
    time_event: (eventName) => {
      if (canTrackEvent) mixpanel.time_event(eventName);
    },
    people: {
      set: (props) => {
        if (canTrackEvent) mixpanel.people.set(props);
      }
    },
    reset: () => {
      if (canTrackEvent) mixpanel.reset();
    }
};

export default MixPanel