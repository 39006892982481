import { useContext, useEffect, lazy, Suspense } from "react";
import { UIView, useRouter } from "@uirouter/react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
// services
import AuthContext from "../../authService/Context";
// Page
import LoadingUser from "./LoadingUser";
import last from "lodash/last";
import { useIsAuthenticated } from "@azure/msal-react";

const LoginPage = lazy(() => import("./Login"));
const RolesNotFound = lazy(() => import("./RolesNotFound"));
// layout
const Layout = lazy(() => import("../../layout"));

export default function Base() {
  const { user, getting } = useContext(AuthContext);
  const router = useRouter();
  const isMsalAuthenticated = useIsAuthenticated();
  const { roles, isDrAuthenticated } = user

  const isAuthenticated = isMsalAuthenticated || isDrAuthenticated

  useEffect(() => {
    if (!isAuthenticated) router.stateService.go("App");
    else {
      const currentView = last(router.stateService.getCurrentPath())?.state
        ?.name;

      currentView === "App" && router.stateService.go("App.Dashboard");
    }
  }, [isMsalAuthenticated, router.stateService, isDrAuthenticated]);

  if (getting) return <LoadingUser />;
  
  if (isAuthenticated && (!roles || roles.length === 0)) return <Suspense fallback={<LoadingUser />}> <RolesNotFound /> </Suspense>

  return (
    <Suspense fallback={<LoadingUser />}>
      {isAuthenticated ?
        <Layout>
          <UIView name="main" />
        </Layout>
        :
        <LoginPage />
      }
    </Suspense>
  );
}
