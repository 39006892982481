import React, { lazy } from 'react'
import {DashboardOutlined} from '@ant-design/icons'
import lazyComponent from './lazy'

const Dashboard = lazy(() => import('../containers/Dashboard'))

const states =  [
    {
        key:'view_dashboard',
        url:'dashboard',
        name:'App.Dashboard',
        title:'Dashboard',
        data: {
            nav:{
                type:'menu',
                sort:1,
                icon: <DashboardOutlined />
            }
        },
        views: {
            "main@App": lazyComponent(Dashboard)
        }
    }
]

export default states