// view
import Base from "../containers/Base";
// state
import dashboard from './dashboard'
import sdk from './sdk'
import applet from './applet'

const states = [
  {
    key: "view_root",
    url: "/",
    name: "App",
    title: "Dashboard",
    component: Base,
    // redirectTo: (trans) => {
    //   const $state = trans.router.stateService
    //   const toState = trans.to()
    //   const toParams = trans.params('to')

    //   if (toState.name === 'App' && toParams["#"]) {
    //     const states = ['App.SDK']
    //     return find(states, state => $state.target(state).exists())
    //   } else return null
    // }
  },
  ...dashboard,
  ...sdk,
  ...applet,
];

export default states;
