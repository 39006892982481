import { QueryClient, QueryClientProvider, focusManager } from "react-query";

export const client = new QueryClient({
  defaultOptions: {
    queries: {
      // set retry to false prevents infinite refetch on fail
      retry: false,
    },
  },
});

export default function QueryProvider(attr) {
  const props = {
    client,
    contextSharing: true,
    ...attr,
  };

  // Prevent refetch on focus window
  focusManager.setFocused(false);

  return <QueryClientProvider {...props} />;
}
