import React, { lazy } from 'react'
import {MobileOutlined} from '@ant-design/icons'
import lazyComponent from './lazy'

const Campaigns = lazy(() => import('../containers/Applet/Campaigns'))
const Devices = lazy(() => import('../containers/Applet/Devices'))

const states =  [
    {
        key:'view_applet',
        url:'applet',
        name:'App.Applet',
        title:'Applet',
        redirectTo: 'App.Applet.Devices',
        data: {
            nav:{
                type:'menu',
                sort:1,
                icon: <MobileOutlined />
            }
        }
    },
    {
        key: 'view_applet_devices',
        url: '/devices',
        name: 'App.Applet.Devices',
        title: 'Inventory',
        views: {
          "main@App": lazyComponent(Devices),
        },
        data: {
          nav: {
            type: 'menu',
            sort: 1
          }
        }
      },{
        key: 'view_applet_campaigns',
        url: '/campaigns',
        name: 'App.Applet.Campaigns',
        title: 'Campaigns',
        views: {
          "main@App": lazyComponent(Campaigns)
        },
        data: {
          nav: {
            type: 'menu',
            sort: 2
          }
        }
      },
]

export default states